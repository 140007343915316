//External dependencies//
import { AuthenticationDetails } from 'amazon-cognito-identity-js';
import CognitoClient from '@mancho.devs/cognito';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import {
  LoginAction,
  LoginActionTypes,
  signupFailed,
  signupSucceeded,
  signupConfirmCodeFailed,
  SignUp,
  SignIn,
  signInSucceeded,
  signInFailed,
  ForgotPassword,
  ConfirmPassword,
  SignupConfirmCode,
  signupConfirmCodeSucceeded,
  forgotPasswordSucceeded,
  forgotPasswordFailed,
  confirmPasswordSucceeded,
  confirmPasswordFailed,
  signOutSucceeded,
  signOutFailed,
  SignOut,
  initialClientSuccess,
  initialClientFailed,
} from './action';
import { getSession } from '../../../client/graphql';

const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

//регистрация нового пользователя ПОСЛЕ ПАРОЛЯ
export function signUpEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGNUP),
    switchMap(({ password, username }: SignUp) =>
      cognitoClient
        .signUp(username, password)
        .then((value: any) => signupSucceeded(value))
        .catch((error) => signupFailed(error)),
    ),
  );
}

// для подтверждения регистрации нового пользователя 6-КОД
export function signUpConfirmCodeEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGNUP_CONFIRM_CODE),
    switchMap(({ verificationCode, username }: SignupConfirmCode) =>
      cognitoClient
        .signUpConfirmCode(username, verificationCode)
        .then(signupConfirmCodeSucceeded)
        .catch(signupConfirmCodeFailed),
    ),
  );
}

// для входа в систему
export function signInEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGN_IN),
    switchMap(({ password, username }: SignIn) =>
      cognitoClient.signIn(username, password).then(signInSucceeded).catch(signInFailed),
    ),
  );
}

// для сброса пароля
export function forgotPasswordEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.FORGOT_PASSWORD),
    switchMap(({ username }: ForgotPassword) =>
      cognitoClient.forgotPassword(username).then(forgotPasswordSucceeded).catch(forgotPasswordFailed),
    ),
  );
}

//для установления нового пароля
export function confirmPasswordEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.CONFIRM_PASSWORD),
    switchMap(({ username, verificationCode, newPassword }: ConfirmPassword) =>
      cognitoClient
        .confirmPassword(username, verificationCode, newPassword)
        .then(confirmPasswordSucceeded)
        .catch(confirmPasswordFailed),
    ),
  );
}

// для выхода из системы
export function signOutEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGN_OUT),
    switchMap(({}: SignOut) => cognitoClient.signOut().then(signOutSucceeded).catch(signOutFailed)),
  );
}

export function initialUser(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.INIT_CLIENT),
    switchMap(({}) => getSession().then(initialClientSuccess).catch(initialClientFailed)),
  );
}
