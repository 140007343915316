import { Page } from './PagesActions';
import { IsModalOpen, LoginActionTypes } from './action';
import update from 'immutability-helper';

export const ModalOpenInitialState = {
  isModalOpen: false,
  name: '',
};

export const isOpen = (state = ModalOpenInitialState, action) => {
  switch (action.type) {
    case IsModalOpen.OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };
    case IsModalOpen.CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };
    case IsModalOpen.OPEN_CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: action.value,
      };
    default:
      return state;
  }
};

export interface InitialState {
  currentPage: Page;
}

export const initialState = {
  currentPage: Page.INITIAL_MODAL,
};

export const modalState = (state = initialState, action) => {
  // начальное состояние по умолчанию для модального окна
  switch (action.type) {
    case Page.SET_CURRENT_PAGE:
      return {
        currentPage: action.page,
      };
    default:
      return state;
  }
};

//VALIDATION
export const defaultState = {
  email: '',
  emailError: '',
  // emailSuccess: '',
  password: '',
  passwordError: '',
  // passwordSuccess: '',
  // sms: '',
  smsError: '',
  // smsSuccess: '',
  setOtp: '',
  setConfirmPassword: '',
  setConfirmPasswordError: '',
};
const reducerValid = (state = defaultState, action) => {
  switch (action.type) {
    case LoginActionTypes.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case LoginActionTypes.SET_EMAIL_ERROR:
      return {
        ...state,
        emailError: action.payload,
      };
    case LoginActionTypes.SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case LoginActionTypes.SET_PASSWORD_ERROR:
      return {
        ...state,
        passwordError: action.payload,
      };
    case LoginActionTypes.SET_SMS_ERROR:
      return {
        ...state,
        smsError: action.payload,
      };
    case LoginActionTypes.SET_OTP:
      return {
        ...state,
        otp: action.otp,
      };
    case LoginActionTypes.SET_CONFIRM_PASSWORD:
      return {
        ...state,
        setConfirmPassword: action.payload,
      };
    case LoginActionTypes.SET_CONFIRM_PASSWORD_ERROR:
      return {
        ...state,
        setConfirmPasswordError: action.payload,
      };
    default:
      return state;
  }
};

export default reducerValid;

export interface AuthState {
  session?: any;
  user?: boolean;
  email: string;
  loading?: any;
  isSuccess: any;
  result: any;
  resultError: any;
}

export const EpicState: AuthState = {
  loading: false,
  isSuccess: false,
  result: null,
  resultError: null,
  user: false,
  email: '',
};

export const signUpReducer = (state = EpicState, action) => {
  switch (action.type) {
    case LoginActionTypes.SIGNUP:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.SIGNUP_SUCCEEDED:
      return {
        ...state,
        // signUpSuccess: action.result,
        result: action.result,
        loading: false,
      };
    case LoginActionTypes.SIGNUP_FAILED:
      return {
        ...state,
        // signUpError: action.error,
        resultError: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export const signUpConfirmCodeReducer = (state = EpicState, action) => {
  switch (action.type) {
    case LoginActionTypes.SIGNUP_CONFIRM_CODE:
      return {
        ...state,
        loading: true,
        resultError: null,
      };
    case LoginActionTypes.SIGNUP_CONFIRM_CODE_SUCCEEDED:
      return {
        ...state,
        result: action.result,
        loading: false,
        isSuccess: true,
      };
    case LoginActionTypes.SIGNUP_CONFIRM_CODE_FAILED:
      return {
        ...state,
        resultError: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export const signInReducer = (state = EpicState, action) => {
  switch (action.type) {
    case LoginActionTypes.INIT_CLIENT:
      return update(state, {
        user: { $set: false },
      });
    case LoginActionTypes.INIT_CLIENT_SUCCESS:
      const emailAttribute = action.session.find((attr) => attr.Name === 'email');
      const emailValue = emailAttribute ? emailAttribute.Value : null;
      console.log(action.session);
      return update(state, {
        session: { $set: action.session },
        email: { $set: emailValue },
        user: { $set: true },
      });
    case LoginActionTypes.INIT_CLIENT_FAILED:
      return update(state, {
        user: { $set: false },
      });
    case LoginActionTypes.LOGOUT_RESET:
      return EpicState;
    case LoginActionTypes.SIGN_IN:
      return {
        ...state,
        loading: true,
        resultError: null,
      };
    case LoginActionTypes.SIGN_IN_SUCCEEDED:
      return {
        ...state,
        result: action.result.session.idToken.payload.email,
        loading: false,
        isSuccess: true,
        user: true,
        email: action.result.session.idToken.payload.email,
      };
    case LoginActionTypes.SIGN_IN_FAILED:
      return {
        ...state,
        resultError: action.error,
        loading: false,
      };
    case LoginActionTypes.CLEAR_RESULT_ERROR:
      return {
        ...state,
        resultError: null,
      }
    default:
      return state;
  }
};

export const forgotPasswordReducer = (state = EpicState, action) => {
  switch (action.type) {
    case LoginActionTypes.FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.FORGOT_PASSWORD_SUCCEEDED:
      return {
        ...state,
        result: action.result,
        loading: false,
      };
    case LoginActionTypes.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        resultError: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export const confirmPasswordReducer = (state = EpicState, action) => {
  switch (action.type) {
    case LoginActionTypes.CONFIRM_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.CONFIRM_PASSWORD_SUCCEEDED:
      return {
        ...state,
        result: action.result,
        loading: false,
      };
    case LoginActionTypes.CONFIRM_PASSWORD_FAILED:
      return {
        ...state,
        resultError: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export const sigOutReducer = (state = EpicState, action) => {
  switch (action.type) {
    case LoginActionTypes.SIGN_OUT:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.SIGN_OUT_SUCCEEDED:
      return {
        ...state,
        result: action.result,
        loading: false,
      };
    case LoginActionTypes.SIGN_OUT_FAILED:
      return {
        ...state,
        resultError: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export const initialGetDevicesState = {
  currentUser: '',
};

export const saveStateReducer = (state = initialGetDevicesState, action) => {
  switch (action.type) {
    case LoginActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser,
      };
    default:
      return state;
  }
};
