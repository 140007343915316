export type LoginAction =
  | SignupEmailRequest
  | SignupPasswordRequest
  | OpenModalAction
  | EmailAction
  | EmailErrorAction
  | PasswordAction
  | PasswordErrorAction
  | SmsErrorAction
  | SetOtpAction
  | SetConfirmPasswordAction
  | SetConfirmPasswordErrorAction
  | SignUp
  | SignupFailed
  | SignupSucceeded
  | SignupConfirmCode
  | SignupConfirmCodeFailed
  | SignupConfirmCodeSucceeded
  | SignIn
  | SignInFailed
  | SignInSucceeded
  | SignOut
  | SignOutFailed
  | SignOutSucceeded
  | ForgotPassword
  | ForgotPasswordFailed
  | ForgotPasswordSucceeded
  | ConfirmPassword
  | ConfirmPasswordFailed
  | ConfirmPasswordSucceeded
  | EmptyAction
  | InitClient
  | InitClientSuccess
  | InitClientFailed
  | LogoutReset
  | ClearResultError
  ///
  | LoginRequest
  | SetCurrentUser;

export enum IsModalOpen {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  OPEN_CLOSE_MODAL = 'OPEN_CLOSE_MODAL',
}

export enum LoginActionTypes {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  OPEN_CLOSE_MODAL = 'OPEN_CLOSE_MODAL',
  SET_EMAIL = 'SET_EMAIL',
  SET_EMAIL_ERROR = 'SET_EMAIL_ERROR',
  SET_PASSWORD = 'SET_PASSWORD',
  SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR',
  SET_SMS_ERROR = 'SET_SMS_ERROR',
  SET_CONFIRM_PASSWORD = 'SET_CONFIRM_PASSWORD',
  SET_CONFIRM_PASSWORD_ERROR = 'SET_CONFIRM_PASSWORD_ERROR',
  SET_OTP = 'SET_OTP',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  SIGNUP = 'SIGNUP',
  SIGNUP_EMAIL_REQUEST = 'SIGNUP_EMAIL_REQUEST',
  SIGNUP_PASSWORD_REQUEST = 'SIGNUP_PASSWORD_REQUEST',
  SIGNUP_FAILED = 'SIGNUP_FAILED',
  SIGNUP_SUCCEEDED = 'SIGNUP_SUCCEEDED',
  SIGNUP_CONFIRM_CODE = 'SIGNUP_CONFIRM_CODE',
  SIGNUP_CONFIRM_CODE_FAILED = 'SIGNUP_CONFIRM_CODE_FAILED',
  SIGNUP_CONFIRM_CODE_SUCCEEDED = 'SIGNUP_CONFIRM_CODE_SUCCEEDED',
  SIGN_IN = 'SIGN_IN',
  SIGN_IN_FAILED = 'SIGN_IN_FAILED',
  SIGN_IN_SUCCEEDED = 'SIGN_IN_SUCCEEDED',
  SIGN_OUT = 'SIGN_OUT',
  SIGN_OUT_FAILED = 'SIGN_OUT_FAILED',
  SIGN_OUT_SUCCEEDED = 'SIGN_OUT_SUCCEEDED',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED',
  FORGOT_PASSWORD_SUCCEEDED = 'FORGOT_PASSWORD_SUCCEEDED',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  CONFIRM_PASSWORD_FAILED = 'CONFIRM_PASSWORD_FAILED',
  CONFIRM_PASSWORD_SUCCEEDED = 'CONFIRM_PASSWORD_SUCCEEDED',
  DEFAULT_ACTION_TYPE = 'DEFAULT_ACTION_TYPE',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  INIT_CLIENT = 'INIT_CLIENT',
  INIT_CLIENT_SUCCESS = 'INIT_CLIENT_SUCCESS',
  INIT_CLIENT_FAILED = 'INIT_CLIENT_FAILED',
  LOGOUT_RESET = 'LOGOUT_RESET', //очищает локалстораж
  CLEAR_RESULT_ERROR = 'CLEAR_RESULT_ERROR',
}

//OPEN_MODAL AND CLOSE MODAL
export interface OpenModalAction {
  type: IsModalOpen.OPEN_MODAL;
}

export const openModal = (): OpenModalAction => {
  return {
    type: IsModalOpen.OPEN_MODAL,
  };
};
export const closeModal = () => {
  return {
    type: IsModalOpen.CLOSE_MODAL,
  };
};
export const openCloseModal = (value: boolean) => {
  return {
    type: IsModalOpen.OPEN_CLOSE_MODAL,
    value,
  };
};

//VALIDATION
export interface EmailAction {
  type: LoginActionTypes.SET_EMAIL;
  payload: string;
}
export interface EmailErrorAction {
  type: LoginActionTypes.SET_EMAIL_ERROR;
  payload: string;
}
export interface PasswordAction {
  type: LoginActionTypes.SET_PASSWORD;
  payload: string;
}
export interface PasswordErrorAction {
  type: LoginActionTypes.SET_PASSWORD_ERROR;
  payload: string;
}
export interface SmsErrorAction {
  type: LoginActionTypes.SET_SMS_ERROR;
  payload: string;
}
export interface SetOtpAction {
  type: LoginActionTypes.SET_OTP;
  otp: string;
}
export interface SetConfirmPasswordAction {
  type: LoginActionTypes.SET_CONFIRM_PASSWORD;
  payload: string;
}
export interface SetConfirmPasswordErrorAction {
  type: LoginActionTypes.SET_CONFIRM_PASSWORD_ERROR;
  payload: string;
}
export interface LoginRequest {
  type: LoginActionTypes.LOGIN_REQUEST;
  username: string;
  password: string;
}
export interface SignUp {
  type: LoginActionTypes.SIGNUP;
  username: string;
  password: string;
}
export interface SignupEmailRequest {
  type: LoginActionTypes.SIGNUP_EMAIL_REQUEST;
  username: string;
}
export interface SignupPasswordRequest {
  type: LoginActionTypes.SIGNUP_PASSWORD_REQUEST;
  username: string;
  password: string;
  passwordRepeat: string;
}
export interface SignupFailed {
  type: LoginActionTypes.SIGNUP_FAILED;
  error: Error;
}
export interface SignupSucceeded {
  type: LoginActionTypes.SIGNUP_SUCCEEDED;
  result: {};
}
export interface SignupConfirmCode {
  type: LoginActionTypes.SIGNUP_CONFIRM_CODE;
  username: string;
  verificationCode: string;
}
export interface SignupConfirmCodeFailed {
  type: LoginActionTypes.SIGNUP_CONFIRM_CODE_FAILED;
  error: Error;
}
export interface SignupConfirmCodeSucceeded {
  type: LoginActionTypes.SIGNUP_CONFIRM_CODE_SUCCEEDED;
  result: {};
}
export interface SignIn {
  type: LoginActionTypes.SIGN_IN;
  username: string;
  password: string;
}
export interface SignInFailed {
  type: LoginActionTypes.SIGN_IN_FAILED;
  error: Error;
}
export interface SignInSucceeded {
  type: LoginActionTypes.SIGN_IN_SUCCEEDED;
  result: {};
}
export interface SignOut {
  type: LoginActionTypes.SIGN_OUT;
}
export interface SignOutFailed {
  type: LoginActionTypes.SIGN_OUT_FAILED;
  error: Error;
}
export interface SignOutSucceeded {
  type: LoginActionTypes.SIGN_OUT_SUCCEEDED;
  result: {};
}
export interface ForgotPassword {
  type: LoginActionTypes.FORGOT_PASSWORD;
  username: string;
}
export interface ForgotPasswordFailed {
  type: LoginActionTypes.FORGOT_PASSWORD_FAILED;
  error: Error;
}
export interface ForgotPasswordSucceeded {
  type: LoginActionTypes.FORGOT_PASSWORD_SUCCEEDED;
  result: {};
}
export interface ConfirmPassword {
  type: LoginActionTypes.CONFIRM_PASSWORD;
  username: string;
  verificationCode: string;
  newPassword: string;
}
export interface ConfirmPasswordFailed {
  type: LoginActionTypes.CONFIRM_PASSWORD_FAILED;
  error: Error;
}
export interface ConfirmPasswordSucceeded {
  type: LoginActionTypes.CONFIRM_PASSWORD_SUCCEEDED;
  result: {};
}
export interface EmptyAction {
  type: LoginActionTypes.DEFAULT_ACTION_TYPE;
}
export interface SetCurrentUser {
  type: LoginActionTypes.SET_CURRENT_USER;
  currentUser: string;
}
export interface InitClient {
  type: LoginActionTypes.INIT_CLIENT;
}
export interface InitClientSuccess {
  type: LoginActionTypes.INIT_CLIENT_SUCCESS;
  session: any;
}
export interface InitClientFailed {
  type: LoginActionTypes.INIT_CLIENT_FAILED;
  error: string;
}
export interface LogoutReset {
  type: LoginActionTypes.LOGOUT_RESET;
}
export interface ClearResultError {
  type: LoginActionTypes.CLEAR_RESULT_ERROR;
  payload: string;
}

export const setEmail = (email: string): EmailAction => ({
  type: LoginActionTypes.SET_EMAIL,
  payload: email,
});
export const setEmailError = (error: string): EmailErrorAction => ({
  type: LoginActionTypes.SET_EMAIL_ERROR,
  payload: error,
});
export const setPassword = (password: string): PasswordAction => ({
  type: LoginActionTypes.SET_PASSWORD,
  payload: password,
});
export const setPasswordError = (error: string): PasswordErrorAction => ({
  type: LoginActionTypes.SET_PASSWORD_ERROR,
  payload: error,
});
export const setSmsError = (error: string): SmsErrorAction => ({
  type: LoginActionTypes.SET_SMS_ERROR,
  payload: error,
});
export const setOtp = (otp: string): SetOtpAction => ({
  type: LoginActionTypes.SET_OTP,
  otp,
});
export const setConfirmPassword = (password: string): SetConfirmPasswordAction => ({
  type: LoginActionTypes.SET_CONFIRM_PASSWORD,
  payload: password,
});
export const setConfirmPasswordError = (error: string): SetConfirmPasswordErrorAction => ({
  type: LoginActionTypes.SET_CONFIRM_PASSWORD_ERROR,
  payload: error,
});
///-----------------------------------------///
export function signupEmailRequest(username: string): SignupEmailRequest {
  return {
    type: LoginActionTypes.SIGNUP_EMAIL_REQUEST,
    username,
  };
}
export function signupPasswordRequest(
  username: string,
  password: string,
  passwordRepeat: string,
): SignupPasswordRequest {
  return {
    type: LoginActionTypes.SIGNUP_PASSWORD_REQUEST,
    username,
    password,
    passwordRepeat,
  };
}
///-----------------------------------------///
export function signUp(username: string, password: string): SignUp {
  return {
    type: LoginActionTypes.SIGNUP,
    username,
    password,
  };
}
export function signupFailed(error: Error): SignupFailed {
  return {
    type: LoginActionTypes.SIGNUP_FAILED,
    error,
  };
}
export function signupSucceeded(result): SignupSucceeded {
  return {
    type: LoginActionTypes.SIGNUP_SUCCEEDED,
    result,
  };
}
export function signOut(): SignOut {
  return {
    type: LoginActionTypes.SIGN_OUT,
  };
}
export function signOutFailed(error: Error): SignOutFailed {
  return {
    type: LoginActionTypes.SIGN_OUT_FAILED,
    error,
  };
}
export function signOutSucceeded(result): SignOutSucceeded {
  return {
    type: LoginActionTypes.SIGN_OUT_SUCCEEDED,
    result,
  };
}
export function signupConfirmCode(username: string, verificationCode: string): SignupConfirmCode {
  return {
    type: LoginActionTypes.SIGNUP_CONFIRM_CODE,
    username,
    verificationCode,
  };
}
export function signupConfirmCodeFailed(error: Error): SignupConfirmCodeFailed {
  return {
    type: LoginActionTypes.SIGNUP_CONFIRM_CODE_FAILED,
    error,
  };
}
export function signupConfirmCodeSucceeded(result): SignupConfirmCodeSucceeded {
  return {
    type: LoginActionTypes.SIGNUP_CONFIRM_CODE_SUCCEEDED,
    result,
  };
}
export function signIn(username: string, password: string): SignIn {
  return {
    type: LoginActionTypes.SIGN_IN,
    username,
    password,
  };
}
export function signInFailed(error: Error): SignInFailed {
  return {
    type: LoginActionTypes.SIGN_IN_FAILED,
    error,
  };
}
export function signInSucceeded(result): SignInSucceeded {
  return {
    type: LoginActionTypes.SIGN_IN_SUCCEEDED,
    result,
  };
}
export function forgotPassword(username: string): ForgotPassword {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD,
    username,
  };
}
export function forgotPasswordFailed(error: Error): ForgotPasswordFailed {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD_FAILED,
    error,
  };
}
export function forgotPasswordSucceeded(result): ForgotPasswordSucceeded {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD_SUCCEEDED,
    result,
  };
}
export function confirmPassword(username: string, verificationCode: string, newPassword: string): ConfirmPassword {
  return {
    type: LoginActionTypes.CONFIRM_PASSWORD,
    username,
    verificationCode,
    newPassword,
  };
}
export function confirmPasswordFailed(error: Error): ConfirmPasswordFailed {
  return {
    type: LoginActionTypes.CONFIRM_PASSWORD_FAILED,
    error,
  };
}
export function confirmPasswordSucceeded(result): ConfirmPasswordSucceeded {
  return {
    type: LoginActionTypes.CONFIRM_PASSWORD_SUCCEEDED,
    result,
  };
}
///-----------------------------------------///
export function initialClient(): InitClient {
  return {
    type: LoginActionTypes.INIT_CLIENT,
  };
}
export function initialClientSuccess(session: any): InitClientSuccess {
  return {
    type: LoginActionTypes.INIT_CLIENT_SUCCESS,
    session,
  };
}
export function initialClientFailed(error: string): InitClientFailed {
  return {
    type: LoginActionTypes.INIT_CLIENT_FAILED,
    error,
  };
}
export function logoutReset(): LogoutReset { //sigout очистка
  return {
    type: LoginActionTypes.LOGOUT_RESET,
  };
}
export function clearResultError(error: string): ClearResultError {
  return {
    type: LoginActionTypes.CLEAR_RESULT_ERROR,
    payload: error,
  };
}
///----------------нет теста------------------------///
export function loginRequest(username: string, password: string): LoginRequest {
  return {
    type: LoginActionTypes.LOGIN_REQUEST,
    username,
    password,
  };
}
export function setCurrentUser(currentUser: string): SetCurrentUser {
  return {
    type: LoginActionTypes.SET_CURRENT_USER,
    currentUser,
  };
}
///--------------------------------------------------///
