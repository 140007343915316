/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
import getDevices from '../components/devices/getDevices/redux/reducer';
import { confirmPasswordEpic, forgotPasswordEpic, initialUser, signInEpic, signOutEpic, signUpConfirmCodeEpic, signUpEpic } from '../components/login-page/redux/epic';
import reducerValid, { confirmPasswordReducer, forgotPasswordReducer, isOpen, modalState, saveStateReducer, signInReducer, signUpConfirmCodeReducer, signUpReducer, sigOutReducer } from '../components/login-page/redux/reducer';

const rootEpic = combineEpics(
  signUpEpic,
  signUpConfirmCodeEpic,
  signInEpic,
  forgotPasswordEpic,
  confirmPasswordEpic,
  signOutEpic,
  initialUser,
);

const rootReducer = combineReducers({
  saveStateReducer,
  sigOutReducer,
  confirmPasswordReducer,
  forgotPasswordReducer,
  signInReducer,
  signUpConfirmCodeReducer,
  signUpReducer,
  reducerValid,
  modalState,
  getDevices,
  isOpen,
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
