export enum Page {
  INITIAL_MODAL = 'INITIAL_MODAL', //type
  REGISTER_MODAL_EMAIL = 'REGISTER_MODAL_EMAIL',
  REGISTER_MODAL_PASSWORD = 'REGISTER_MODAL_PASSWORD',
  REGISTER_MODAL_SMS = 'REGISTER_MODAL_SMS',
  REGISTER_MODAL_LOADING = 'REGISTER_MODAL_LOADING',
  LOGIN_MODAL_EMAIL = 'LOGIN_MODAL_EMAIL',
  LOGIN_MODAL_PASSWORD = 'LOGIN_MODAL_PASSWORD',
  LOGIN_MODAL_SMS = 'LOGIN_MODAL_SMS',
  LOGIN_MODAL_NEW_PASSWORD = 'LOGIN_MODAL_NEW_PASSWORD',
  LOGIN_MODAL_LOADING = 'LOGIN_MODAL_LOADING',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
}

export interface CurrentPage {
  type: Page.SET_CURRENT_PAGE; //
  page: Page;
}

export function SetCurrentPage(page: Page): CurrentPage {
  return {
    type: Page.SET_CURRENT_PAGE,
    page,
  };
}
