/* External dependencies */
import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { createHttpLink } from '@apollo/client/core';
// import CognitoClient from '@mancho.devs/cognito';
import * as AWSCognito from 'amazon-cognito-identity-js';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { v4 as uuidv4 } from 'uuid';

export let anonymousClient: ApolloClient<NormalizedCacheObject>;

// const cognitoClient = new CognitoClient({
//   UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
//   ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
// });

let credential: any;
export async function setAnonymousClient(): Promise<ApolloClient<NormalizedCacheObject>> {
  if (anonymousClient) {
    return anonymousClient;
  }

  const anonymousClientConfig = {
    url: process.env.GATSBY_APPSYNC_ENDPOINT,
    region: process.env.GATSBY_REGION,
    auth: {
      type: AUTH_TYPE.API_KEY as AUTH_TYPE.API_KEY,
      apiKey: process.env.GATSBY_APPSYNC_API_KEY,
    },
    offlineConfig: {
      keyPrefix: `client-instance-${uuidv4()}`,
    },
  };

  const { url } = anonymousClientConfig;
  const httpLink = createHttpLink({ uri: url });

  const link = ApolloLink.from([
    createAuthLink(anonymousClientConfig),
    createSubscriptionHandshakeLink(anonymousClientConfig, httpLink),
  ]);

  const cache = new InMemoryCache();

  if (typeof window !== 'undefined') {
    await persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
    });
  }

  return (anonymousClient = new ApolloClient({
    link,
    cache,
  }));
}

export function getAnonymousClient() {
  return setAnonymousClient();
}

export async function getCleant(): Promise<any> {
  let userPool;
  let currentUser;
  let expireTime;

  try {
    userPool = new AWSCognito.CognitoUserPool({
      UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
      ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
    });
    currentUser = userPool.getCurrentUser();
    expireTime = credential.expireTime;
  } catch (err) {
    return setAnonymousClient();
  }

  if (currentUser && expireTime && credential.needsRefresh()) {
    const session: any = await new Promise((resolve, reject) => {
      currentUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });

    await new Promise((resolve, reject) => {
      currentUser.refreshSession(session.getRefreshToken(), (err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  }

  return setAnonymousClient();
}

export function getSession() { //для проверки авторизации (получения сешн)
  return new Promise((resolve, reject) => {
    let poolData = {
      UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
      ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
    };

    let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    let cognitoUser = userPool.getCurrentUser();
    cognitoUser.getSession(function (err, session) {
      if (err) {
        reject(err);
        return;
      }
      console.log('session validity: ' + session.isValid());

      cognitoUser.getUserAttributes(function (err, result) {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  });
}